import React from 'react';
import Search from './Search';

const App = () => {
  return (
    <div className="App">
      <Search/>
    </div>
  );
};

export default App;
