import React, { Component } from 'react';

export class Search extends Component {
  state = { options: [] };

  onKeyDown = (event) => {
    if (event.keyCode !== 13) { return; }
    const userInput = event.currentTarget.value;
    if (userInput.length === 0) { this.setState({ options: [] }); return; }
    fetch('https://movietangle.com/search',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input: userInput })
      })
      .then(response => response.json())
      .then(options => { this.setState({ options });
    });
  };

  render() {
    const { onKeyDown, state: { options } } = this;
    let optionList;
    if (options.length > 0) {
      optionList = (
        <ul className="options">
          {options.map(option => {
            return (
              <li className="option"
                  key={option.id}
                  onClick={() => window.open(`https://movietangle.com/id/${option.id}`, '_self') }>
                {info(option)}
              </li>
            );
          })}
        </ul>
      );
    }

    return (
      <React.Fragment>
        <div className="search">
          <input
            type="input"
            className="search-box"
            placeholder="The Shawshank Redemption"
            onKeyDown={onKeyDown}
          />
          {optionList}
        </div>
      </React.Fragment>
    );
  }
}

function info(option) {
  const {name, from, to} = option;
  return name + ' ' + (from === '\\N' ? '' : '(' + from + (to === '\\N' ? '' : '-' + to) + ')');
}

export default Search;
